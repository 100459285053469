// 
// user.js
// Use this to write your custom JS
//

import 'vanilla-cookieconsent/src/cookieconsent';

const cookieconsent = initCookieConsent();

cookieconsent.run({
    autorun: true,
    current_lang: 'en',
    auto_language: 'browser',
    autoclear_cookies: true,
    page_scripts: true,
    gui_options: {
        consent_modal: {
            layout: 'cloud',
            position: 'bottom left',
            transition: 'slide',
            swap_buttons: false,
        },
    },
    languages: {
        'en': {
            consent_modal: {
                title: 'We use cookies!',
                description: "We use cookies to make our website as useful to you as possible. Gather <a href=\"/page/cookies-usage\" target=\"_blank\">details</a> about it.",
                primary_btn: {
                    text: 'Accept all',
                    role: 'accept_all',
                },
            },
            settings_modal: {
                title: 'Cookie preferences ...',
                save_settings_btn: 'Save settings',
                accept_all_btn: 'Accept all',
                blocks: [],
            }
        },
        'ru': {
            consent_modal: {
                title: 'Мы используем файлы cookie!',
                description: "Мы используем файлы cookie, чтобы сделать наш сайт максимально удобным для вас. Узнайте об этом <a href=\"/page/cookies-usage\" target=\"_blank\">подробнее</a>.",
                primary_btn: {
                    text: 'Все понятно',
                    role: 'accept_all',
                },
            },
            settings_modal: {
                title: 'Cookie preferences ...',
                save_settings_btn: 'Save settings',
                accept_all_btn: 'Accept all',
                blocks: [],
            }
        },
    },
});
